/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.


    jQuery( document ).ready(function() {

        if ( jQuery('.breadcrumb li:contains("Operating Advisory Group")').length !== 0 ){
            $('.teammember-disclaimer').show();

        }

        if ( jQuery('.breadcrumb li:contains("Executive Partners")').length !== 0 ){
            $('.ep-disclaimer').show();

        }
    });

	$('.menu-item-has-children').click(function(){
		$(this).find('.sub-menu').toggle();
	});

	$('.menu-item-has-children').mouseover(function(){
		$(this).find('.sub-menu').show();
	});

	$('.menu-item-has-children').mouseout(function(){
		$(this).find('.sub-menu').hide();
	});

	$('.sub-menu').mouseout(function(){
		$(this).hide();
	});

	$('.menu-our-firm').mouseover(function(){
		$('.sub-menu').hide();
	});

	$('.menu-team').mouseover(function(){
		$('.sub-menu').hide();
	});

	$('.menu-news').mouseover(function(){
		$('.sub-menu').hide();
	});

	$('.menu-investment-strategies > a').click(function(){
		$('.menu-portfolio').find('.sub-menu').hide();
	});

	$('.menu-portfolio > a').click(function(){
		$('.menu-investment-strategies').find('.sub-menu').hide();
	});

	$('.menu-investment-strategies > a').mouseover(function(){
		$('.menu-portfolio').find('.sub-menu').hide();
	});

	$('.menu-portfolio > a').mouseover(function(){
		$('.menu-investment-strategies').find('.sub-menu').hide();
	});



// Show Disclaimer
  $('#member-5').click(function(){
        $('.team-disclaimer').show();
  });

  $('#member-6').click(function(){
        $('.ep-disclaimer').show();
  });

//  Hide Disclaimer

  $('#member-1').click(function(){
        $('.team-disclaimer').hide();
        $('.ep-disclaimer').hide();

  });

  $('#member-2').click(function(){
        $('.team-disclaimer').hide();
        $('.ep-disclaimer').hide();

  });
  $('#member-0').click(function(){
        $('.team-disclaimer').hide();
        $('.ep-disclaimer').hide();

  });
  $('#member-4').click(function(){
        $('.team-disclaimer').hide();
        $('.ep-disclaimer').hide();

  });
  $('#member-3').click(function(){
        $('.team-disclaimer').hide();
        $('.ep-disclaimer').hide();

  });
  $('#member-6').click(function(){
        $('.team-disclaimer').hide();
  });

  $('#member-5').click(function(){
        $('.ep-disclaimer').hide();
  });

  $('#all-professionals').click(function(){
        $('.team-disclaimer').hide();
        $('.ep-disclaimer').hide();
  });


  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

		  $("#first_arrow").click(function() {
			  sub = parseInt($('body').css("padding-top"));
			  $('html, body').animate({
				  scrollTop: $(".second_area").offset().top - sub
			  }, 1000);
		  });

		  $("#second_arrow").click(function() {
			  sub = parseInt($('body').css("padding-top"));
			  $('html, body').animate({
				  scrollTop: $(".third_area").offset().top - sub
			  }, 1000);
		  });

		  $(window).load(function() {
              var sub;
			  if (screen.width > 769) {

				  sub = parseInt($('body').css("padding-top"));
				  sub = sub - 25;
				  $('.home_bgs').css("min-height", ($(window).height() - sub));

				  var subtract = parseInt($('body').css("padding-top"), 10) + 55;

				  console.log(subtract);
				  $('.fa-arrow-down').css("top", ($(window).height() - subtract));
				  $('.fa-arrow-down').show();
			  }
			  else {
				  sub = parseInt($('body').css("padding-top"));

				  $('.first_bgs').css("min-height", ($(window).height() - sub));
			  }


		  });
		  $( window ).resize(function() {

			  var sub;
			  if (screen.width > 769) {

				  sub = parseInt($('body').css("padding-top"));
				  sub = sub - 25;
				  console.log(sub);
				  $('.home_bgs').css("min-height", ($(window).height() - sub));
				  var subtract = parseInt($('body').css("padding-top"), 10) + 55;
				  $('.fa-arrow-down').css("top", ($(window).height() - subtract));
			  }
			  else {
				  sub = parseInt($('body').css("padding-top"));

				  $('.first_bgs').css("min-height", ($(window).height() - sub));
			  }

		  });
      }
    },
    'private_equity': {
      init: function() {


      	var hash 	= window.location.hash;
      	var filters = {};

      	// flatten object by concatting values
      	function concatValues( obj ) {
      		var value = '';
      		for ( var prop in obj ) {
      		  value += obj[ prop ];
      		}
      		return value;
      	}

      	// Init Private Equity Grid
      	var $grid_pe = $('.portfolio-items.pe').isotope({
      		itemSelector: '.portfolio-item',
      		layoutMode: 'fitRows',
      	});

      	// Init Direct Lending Grid
      	var $grid_dl = $('.portfolio-items.dl').isotope({
      		itemSelector: '.portfolio-item',
      		layoutMode: 'fitRows',
      	});

      	// layout Isotope after each image loads
      	$grid_pe.imagesLoaded().progress( function() {
      		$grid_pe.isotope('layout');
      	});

      	if(hash) {
      		$("a[href='"+hash+"']").tab('show');
      	}


        $('.toggle-grid').click(function() {
        	$( this ).parents('.tab-pane').find('.portfolio-items').removeClass('list-view');
        	$( this ).parents('.tab-pane').find('.portfolio-items').addClass('grid-view');
        	$grid_pe.isotope('layout');
        	$grid_dl.isotope('layout');
        });
        $('.toggle-list').click(function() {
        	$( this ).parents('.tab-pane').find('.portfolio-items').removeClass('grid-view');
        	$( this ).parents('.tab-pane').find('.portfolio-items').addClass('list-view');
        	$grid_pe.isotope('layout');
        	$grid_dl.isotope('layout');
        });

        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        	$grid_pe.isotope('layout');
        	$grid_dl.isotope('layout');
        });

        $('.private-equity .toggle-filters button').click(function() {
        	var $this = $(this);
        	// get group key
		    var $buttonGroup = $this.parents('.btn-group');
		    var filterGroup = $buttonGroup.attr('data-filter-group');
		    // set filter for group
		    filters[ filterGroup ] = $this.attr('data-filter');
		    // combine filters
		    var filterValue = concatValues( filters );
        	$grid_pe.isotope({ 
        		filter: filterValue, 
        	});
        });

        $('.direct-lending .toggle-filters button').click(function() {
        	var $this = $(this);
        	// get group key
		    var $buttonGroup = $this.parents('.btn-group');
		    var filterGroup = $buttonGroup.attr('data-filter-group');
		    // set filter for group
		    filters[ filterGroup ] = $this.attr('data-filter');
		    // combine filters
		    var filterValue = concatValues( filters );
        	$grid_dl.isotope({ 
        		filter: filterValue, 
        	});
        });

        // change is-checked class on buttons
		$('.btn-group button').click(function() {
			$( this ).parent('.btn-group').find('button').removeClass('active');
			$( this ).addClass('active');
		});

        
      },
      finalize: function() {
        
      }
    },
      'direct_lending': {
          init: function() {


              var hash 	= window.location.hash;
              var filters = {};

              // flatten object by concatting values
              function concatValues( obj ) {
                  var value = '';
                  for ( var prop in obj ) {
                      value += obj[ prop ];
                  }
                  return value;
              }

              // Init Private Equity Grid
              var $grid_pe = $('.portfolio-items.pe').isotope({
                  itemSelector: '.portfolio-item',
                  layoutMode: 'fitRows',
              });

              // Init Direct Lending Grid
              var $grid_dl = $('.portfolio-items.dl').isotope({
                  itemSelector: '.portfolio-item',
                  layoutMode: 'fitRows',
              });

              // layout Isotope after each image loads
              $grid_pe.imagesLoaded().progress( function() {
                  $grid_pe.isotope('layout');
              });

              if(hash) {
                  $("a[href='"+hash+"']").tab('show');
              }


              $('.toggle-grid').click(function() {
                  $( this ).parents('.tab-pane').find('.portfolio-items').removeClass('list-view');
                  $( this ).parents('.tab-pane').find('.portfolio-items').addClass('grid-view');
                  $grid_pe.isotope('layout');
                  $grid_dl.isotope('layout');
              });
              $('.toggle-list').click(function() {
                  $( this ).parents('.tab-pane').find('.portfolio-items').removeClass('grid-view');
                  $( this ).parents('.tab-pane').find('.portfolio-items').addClass('list-view');
                  $grid_pe.isotope('layout');
                  $grid_dl.isotope('layout');
              });

              $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
                  $grid_pe.isotope('layout');
                  $grid_dl.isotope('layout');
              });

              $('.direct-lending .toggle-filters button').click(function() {
                  var $this = $(this);
                  // get group key
                  var $buttonGroup = $this.parents('.btn-group');
                  var filterGroup = $buttonGroup.attr('data-filter-group');
                  // set filter for group
                  filters[ filterGroup ] = $this.attr('data-filter');
                  // combine filters
                  var filterValue = concatValues( filters );
                  $grid_pe.isotope({
                      filter: filterValue,
                  });
              });

              $('.direct-lending .toggle-filters button').click(function() {
                  var $this = $(this);
                  // get group key
                  var $buttonGroup = $this.parents('.btn-group');
                  var filterGroup = $buttonGroup.attr('data-filter-group');
                  // set filter for group
                  filters[ filterGroup ] = $this.attr('data-filter');
                  // combine filters
                  var filterValue = concatValues( filters );
                  $grid_dl.isotope({
                      filter: filterValue,
                  });
              });

              // change is-checked class on buttons
              $('.btn-group button').click(function() {
                  $( this ).parent('.btn-group').find('button').removeClass('active');
                  $( this ).addClass('active');
              });


          },
          finalize: function() {

          }
      },
    'post_type_archive_team_members': {
    	init: function() {

    		var hash = window.location.hash;

    		// init isotope
    		var $grid = $('.display-team-members').isotope({
	    		// options
	    		itemSelector: '.team-member',
	    		layoutMode: 'fitRows',
	    		getSortData: {
	    			name: '[data-last-name]',
	    			order: '[data-order]'
	    		},
	    		sortBy : 'name'
    		});

    		// Set layout after images load to prevent offset
    		$grid.imagesLoaded().progress( function() {
    			$grid.isotope('layout');
    		});



    		// Check if hash in URL if so set filter and button active state
    		if(hash === "#all-professionals") {
    			$grid.isotope({ 
    				filter: "*",
    				sortBy : 'order' 
    			});
    		} else if (hash) {
    			hash = hash.replace("#", ".branch-");
    			$grid.isotope({ 
    				filter: hash,
    				sortBy : 'order' 
    			});
    			$('.display-team-members.all-professionals').addClass('hidden');
    			$('.display-team-members.by-branch').removeClass('hidden');
    			$grid.isotope('layout');
    			$( '.filter-tabs li' ).removeClass( 'active' );
    			$("a[data-filter='"+hash+"']").parent('li').addClass('active');
    		}

            console.log(hash);
    		if(hash === ".branch-6operating-advisory-group") {
                $('.team-disclaimer').css("display","block");
                console.log(hash);
			}
      if(hash === ".branch-7executive-partners") {
                $('.ep-disclaimer').css("display","block");
                console.log(hash); }
      


    		// bind filter button click
    		$('.filter-tabs a').click(function() {
    			//set vars
    			var filterValue = $( this ).attr( 'data-filter' );
    			var link		= $( this ).attr( 'href' );

    			window.location.hash = link;

    			if( link === '#all-professionals') {
    				$grid.isotope({ 
    					filter: filterValue,
    					sortBy : 'name' 
    				});
    				$('.display-team-members.all-professionals').removeClass('hidden');
    				$('.display-team-members.by-branch').addClass('hidden');
    				$grid.isotope('layout');
    			} else {
    				$grid.isotope({ 
    					filter: filterValue,
    					sortBy : 'order' 
    				});
    				$('.display-team-members.all-professionals').addClass('hidden');
    				$('.display-team-members.by-branch').removeClass('hidden');
    				$grid.isotope('layout');
    			}

    			// button active state
    			$( '.filter-tabs li' ).removeClass( 'active' );
    			$( this ).parent('li').addClass( 'active' );
    		});
    	},
    },
    // About us page, note the change from about-us to about_us.
    'post_type_single_team_members': {
      init: function() {
          console.log( "ready!" );
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
